import type { ClusterParams, ClusterStatus, PaymentIntent, ClusterApp } from '../types/cluster';
import { cloudApiConfig } from '../config';
import { User } from 'oidc-react';

export const apiRoot = `${cloudApiConfig.apiRoot}/api/v1`;
export const clusterRoot = `${apiRoot}/clouds`
const ingressFirstByName = (a: ClusterApp, b: ClusterApp) => {
  // Check if a.href or a.hrefs exists and is non-empty
  const aHasHref = a?.status?.href?.length || a?.status?.hrefs?.length;
  // Check if b.href or b.hrefs exists and is non-empty
  const bHasHref = b?.status?.href?.length || b?.status?.hrefs?.length;

  // If only one has href or hrefs, prioritize that one
  if (aHasHref && !bHasHref) {
    return -1;
  }
  if (!aHasHref && bHasHref) {
    return 1;
  }

  // If both have or both do not have href or hrefs, compare by name
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

interface CFRedirect {
  redirect: string;
}

export interface AWSDetail {
  id: string;
  microARN: string;
  role: string;
  canAssume: boolean;
}

export interface Profile {
  stripe: boolean;
  aws: AWSDetail;
  user: User;
}

export const clusterApi = (user: User) => {
  const opts = () => ({
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return {
    async saveStripeIntent(intent: string): Promise<void> {
      await fetch(`${apiRoot}/payments`, { ...opts(), method: 'POST', body: JSON.stringify({intent}) });
    },

    async checkExists(c: string): Promise<boolean> {
      const res = await fetch(`${clusterRoot}/${c}/exists`, { ...opts() });
      return res.status === 409;
    },

    async getStripeIntent(): Promise<PaymentIntent> {
      const res = await fetch(`${apiRoot}/payments`, { ...opts() });
      return await res.json() as PaymentIntent;
    },

    async getProfile(): Promise<Profile> {
      try {
        const res = await fetch(`${apiRoot}/me`, { ...opts() });
        return await res.json() as Profile;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getCloudformation(id: string): Promise<string> {
      try {
        const res = await fetch(`${clusterRoot}/aws/cloudformation`, {
          method: 'POST',
          body: JSON.stringify({accountID: id}),
          ...opts(),
        })
        const j = await res.json() as CFRedirect;
        return j.redirect;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async listDNS(): Promise<string[]> {
      try {
        const res = await fetch(`${clusterRoot}/dns`, opts());
        return await res.json() as string[];
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async listClusters(): Promise<ClusterStatus[]> {
      try {
        const res = await fetch(`${clusterRoot}/`, { ...opts() });
        return await res.json() as ClusterStatus[];
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async refreshCluster(name: string): Promise<void> {
      try {
        await fetch(`${clusterRoot}/${name}/refresh`, { ...opts(), method: 'POST'});
      } catch (error) {
        console.error(error);
        throw error;
      }

    },

    async getCluster(name: string): Promise<ClusterStatus> {
      try {
        const res = await fetch(`${clusterRoot}/${name}`, { ...opts() });
        return await res.json() as ClusterStatus;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getClusterApps(name: string): Promise<ClusterApp[]> {
      try {
        const res = await fetch(`${clusterRoot}/${name}/apps`, { ...opts() });
        const apps = await res.json() as ClusterApp[];
        apps.sort(ingressFirstByName);
        return apps;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async createCluster(s: ClusterParams): Promise<ClusterParams> {
      const {
        name,
        ...body
      } = s;
      const res = await fetch(`${clusterRoot}/${name}`, {
        method: 'PUT',
        headers: {
          ...opts().headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      return await res.json() as ClusterParams;
    },

    async deleteCluster(s: string): Promise<void> {
      await fetch(`${clusterRoot}/${s}`, {
        ...opts(),
        method: 'DELETE',
      });
    },

    async getKubeconfig(cluster: string, openid: boolean): Promise<string> {
      const c = await fetch(`${clusterRoot}/${cluster}/kubeconfig?oidc=${openid === true}`, { ...opts() });
      return c.text();
    },

    async saveAddress(addr: string): Promise<void> {
    }
  };
};
