import { useState } from 'react';
import { Link, Button, Typography, Menu, MenuItem } from '@mui/material';
// import { Delete, Autorenew } from '@mui/icons-material';

// import Cost from '../components/cost';
// import Loader from '../components/loader';
// import useCluster from '../hooks/cluster';
import { ClusterStatus, ClusterApp } from '../types/cluster';

export interface AppCardProps {
  app: ClusterApp;
  name?: string;
  c: ClusterStatus;
}

const AppCard = ({app, name, c}: AppCardProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!app.status.hrefs?.length) {
    return (<Typography>{app.name}</Typography>);
  }

  if (app.status.hrefs?.length === 1) {
    const hr = app.status.hrefs[0];
    return (
      <Link href={ hr } color="textPrimary" variant="h5" target="_blank" >
        <Button variant="text">ns: {app.name.split('/')[0]} </Button>
      </Link>
    );
  }
  if (app.status.hrefs?.length > 1) {
    const items = app.status.hrefs.map(h => (<MenuItem><Link href={h} target="_blank">{h}</Link></MenuItem>));
    return (
      <>
        <Button id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
          { app.name.split('/')[0] }
        </Button>
        <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }} >
          {items}
        </Menu>
      </>
    );
  }
  return (<Typography>{app.name}</Typography>);
};

export default AppCard;
