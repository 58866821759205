import React from 'react';
import {Link} from 'react-router-dom';
// import { useAuth } from 'oidc-react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Fab,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Visibility, Add, Refresh } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

import Cost from '../components/cost';
import Loader from '../components/loader';
import useCluster from '../hooks/clusters';
import useProfile from '../hooks/profile';

export function Cluster() {
  const { error, loading, clusters, deleteCluster, refreshCluster } = useCluster();
  const nav = useNavigate();
  const { loading: profileLoading, profile } = useProfile();

  if (error) {
    return (
      <div>Error: {error}</div>
    );
  }

  if (!profileLoading && !profile.stripe) {
    nav('/signup');
  }

  if (!profileLoading && !profile.aws.id) {
    nav('/link');
  }

  if (loading || profileLoading) {
    return (
      <Loader/>
    );
  }

  if (!clusters?.length) {
    return (
      <Container sx={{ '> *': {m: 5, textAlign: 'center'}}}>
        <Typography variant="h2">
          Looks like you don't have any clusters.
        </Typography>
        <Typography>
          Maybe <Link to="/cluster/new">create one.</Link>
        </Typography>
      </Container>
    );
  }


  const c = clusters.map(f => {
    const sub = (
      <Tooltip title={f?.created?.toLocaleString()} placement="right">
        <Typography>Created {formatDistanceToNow(new Date(f?.created))} ago</Typography>
      </Tooltip>
    );

    return (
    <Card key={f.name} sx={{ margin: '1rem' }}>
      <CardHeader title={f.name} subheader={sub} sx={{ cursor: 'pointer' }} onClick={() => nav(`/cluster/${f.name}`)}>
      </CardHeader>
      <CardContent>
        <Typography>Stage: {f.micro.stage}</Typography>
        <Cost pennies={f.micro.usage} />
      </CardContent>
      <CardActions>
        <IconButton aria-label="delete cluster" onClick={() => deleteCluster(f.name)}>
          <Delete/>
        </IconButton>
        <IconButton aria-label="refresh cluster" onClick={() => refreshCluster(f.name)}>
          <Refresh />
        </IconButton>
        <IconButton aria-label="refresh cluster" onClick={() => nav(`/cluster/${f.name}`)}>
          <Visibility />
        </IconButton>
      </CardActions>
    </Card>
    );
  })

  return (
    <>
    <Container>
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }} >
      {c}
    </Box>
  </Container>
    <Fab variant="extended" sx={{ position: 'absolute', bottom: 30, right: 30 }} aria-label="add" onClick={() => nav('/cluster/new')}><Add /></Fab>
    </>
  );
}
