export const cloudApiConfig = {
  apiRoot: process.env.REACT_APP_API_ROOT || 'https://api.ucml.dev',
}

export const stripeApiConfig = (process.env.REACT_APP_ENV || process.env.NODE_ENV) === 'production' ? {
  token: 'pk_live_51IH2WOL6iPPuzcmGCR3iukCyqYnWLolJuaSXyljuuA2j6r8KU7GMzZZ5LJEoL5fZMbI5kULFEttQX0Iqpim06xBW00HDyIOIrH',
  login: '4gwdStecYalC0GQ288',
  redirectURL: 'https://ucml.dev/signup',
} : {
  token: 'pk_test_51IH2WOL6iPPuzcmG8qQ8LCPnsuQ7Vk9uUvI1aKdcWJs6D4mURLZk8pszKmTZTmbAtiPzK81oYHE1hJd8KP5mInWR00v25Cvmtk',
  login: 'test_7sIeUW7Kg6KN57q9AB',
  redirectURL: `${window.location.origin}/signup`,
};
