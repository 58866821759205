import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Input,
  TextField,
  // List,
  // ListItem,
  // ListItemText,
  // ListItemIcon
} from '@mui/material';

import { Replaces, totalPrice } from '../components/replaces';
import { apiRoot } from '../api/clusterApi';

// import { Link } from 'react-router-dom';
// import {
//   BarChart as ScalableIcon, Build as AutomationIcon,
//   Security as SecurityIcon,
//   GitHub as GitHubIcon,
//   Visibility as ObservabilityIcon,
//   Extension as EcosystemIcon,
//   DeveloperMode as DeveloperIcon,
//   Cloud as DeploymentIcon,
//   Key,
// } from '@mui/icons-material';

interface Price {
  priceID: string;
  amount: number;
}

export function Pricing() {
  const [price, setPrice] = useState<Price>({priceID: '', amount: 5});
  const [teamSize, setTeamSize] = useState<number>(1);
  const [orgSize, setOrgSize] = useState<number>(1);
  const [salary, setSalary] = useState<number>(100000);
  useEffect(() => {
    const fetchPrice = async () => {
      const response = await fetch(`${apiRoot}/pricing`);
      const price = await response.json() as Price;
      setPrice(price);
    };
    fetchPrice();
  }, []);

  const fmtMinute = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price.amount / 100);

  const fmtHour = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price.amount / 100 * 60);

  const fmtDay = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price.amount / 100 * 60 * 24);

  const fmtMonth = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price.amount / 100 * 60 * 24 * 30);

  const fmtYear = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price.amount / 100 * 60 * 24 * 365);

  const formattedAvgSalary = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(salary);

  const formattedSalary = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(salary*teamSize);

  const formattedSavings = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(salary*teamSize - price.amount / 100 * 60 * 24 * 365 + totalPrice(orgSize));

  return (
    <>
    <Container sx={{ py: 6 }}>
    <Typography variant="h2" sx={{ mb: 2 }}>
    Microcumulus Pricing
    <Box component="span" sx={{ m:2 }}>
    <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Logo" height="40"/>
    </Box>
    </Typography>

    <Typography variant="h4" sx={{ my: 2 }}>
    The price of using microcumulus for any number of clusters is currently {fmtMinute} per minute.
      </Typography>
    <Typography variant="h5" sx={{ my: 2 }}>
    This does not include the cost of the underlying cloud infrastructure, which is handled on your own cloud account, though the way we build and run clusters is designed to be as cost affordable as possible on most cloud providers.
      </Typography>
    <Typography variant="h5" sx={{ my: 2 }}>
    That's {fmtHour} per hour.
      </Typography>
    <Typography variant="h5" sx={{ my: 2 }}>
    {fmtDay} per day.
      </Typography>
    <Typography variant="h5" sx={{ my: 2 }}>
    {fmtMonth} per month.
      </Typography>
    <Typography variant="h5" sx={{ my: 2 }}>
    {fmtYear} per year.
      </Typography>
    </Container>

    <Container>
    <Typography variant="h4" sx={{ mb: 2 }}>
    How does this compare to doing it yourself?
    </Typography>
    <TextField
      placeholder="Number of platform engineers"
      inputProps={{ 'aria-label': 'Number of platform engineers' }}
      onChange={(e) => setTeamSize(parseInt(e.target.value))}
      value={teamSize}
      label="Number of platform engineers"
      type="number"
    />
    <TextField
      placeholder="Average salary"
      inputProps={{ 'aria-label': 'Average salary' }}
      onChange={(e) => setSalary(parseInt(e.target.value))}
      value={salary}
      label="Average platform eng salary"
    />
    <TextField
      placeholder="Organization size"
      inputProps={{ 'aria-label': 'Organization size' }}
      onChange={(e) => setOrgSize(parseInt(e.target.value))}
      value={orgSize}
      label="Organization size"
      type="number"
    />

    <Typography variant="h5" sx={{ my: 2 }}>
    The average salary of a software engineer in the US is {formattedAvgSalary} per year.
      </Typography>
    <Typography variant="h5" sx={{ my: 2 }}>
    That means that if you have a team of {teamSize} platform engineers, you are paying {formattedSalary} per year.
      </Typography>
    <Typography variant="h5" sx={{ my: 2 }}>
    Microcumulus will save you {formattedSavings} per year, in addition to time saved by actually using our platform.
    </Typography>
    </Container>
    <Replaces orgSize={orgSize}/>
    </>
  );
}
