import { useState, useEffect } from 'react';
import { useAuth, User } from 'oidc-react';
import { clusterApi, Profile } from '../api/clusterApi';

export default function useProfile() {
  const [profile, setProfile] = useState({aws: {}} as Profile);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({} as Error);
  const auth = useAuth();
  const [api, setApi] = useState(clusterApi(auth.userData as User));

  useEffect(() => {
    if (!auth?.userData) {
      return
    }
    const api = clusterApi(auth.userData as User);
    setApi(api);
  }, [auth?.userData])

  useEffect(() => {
    const loadProfile = async () => {
      if (!auth?.userData) {
        return;
      }
      try {
        const c = await api.getProfile();
        setProfile(c);
        setLoading(false);
      } catch (e) {
        console.error('Error loading cluster data: ', e);
        setError(e as Error);
        // throw e;
      }
    }

    loadProfile()
    const t = setInterval(loadProfile, 15_000)
    return () => {
      clearInterval(t)
    };
  }, [api, auth?.userData])

  return {
    loading,
    profile,
    error,
    createCluster: api.createCluster,
    deleteCluster: api.deleteCluster,
    refreshCluster: api.refreshCluster
  }
};
