import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Box, Grid, TextField, Button, Tab, Tabs, Paper } from '@mui/material';
import { useAuth, User } from 'oidc-react';
import { clusterApi } from '../api/clusterApi';

const permissions = [
  'AmazonEC2FullAccess',
  'AmazonRoute53FullAccess',
  'AmazonS3FullAccess',
  'IAMFullAccess',
  'AmazonVPCFullAccess',
  'AmazonSQSFullAccess',
  'AmazonEventBridgeFullAccess',
];

function CodeList({array}: {array: string[]}) {
  return (
    <>
    {array.map((text, index) => (
        <React.Fragment key={text}>
          <Box
            component="span"
            sx={{
                bgcolor: 'grey.900',
                color: 'white',
                fontFamily: 'Monospace',
                display: 'inline-block'
            }}
          >
            {text}
          </Box>
            {index === array.length-1 ? '' : ','}&nbsp;
        </React.Fragment>
          ))}
    </>
  );
}

const cliUpdate = (
  <>
    <Box mt={2}>
      <code>
        <pre>
          curl "https://git.stuart.fun/api/v4/projects/402/jobs/artifacts/main/raw/bin/$(uname)/mu?job=build:go" -o ./mu && chmod +x mu && ./mu setup
        </pre>
      </code>
    </Box>
    <Typography>
      After running the above command, you should be set up and ready to move on to <Link to="/clusters">your cluster page</Link> or <Link to="/cluster/new">creating your first cluster</Link>.
    </Typography>
    {/* <Box mt={2}> */}
    {/*   <Typography> */}
    {/*     Congratulations! Your AWS account is now linked to Microcumulus™, and you can start managing your clusters with ease. */}
    {/*   </Typography> */}
    {/* </Box> */}
  </>
);

export const LinkAccount = () => {
  const auth = useAuth();
  const api = clusterApi(auth.userData as User);

  const [awsID, setAWSID] = useState('');
  const [redir, setRedir] = useState('');
  const [policy, setPolicy] = useState('');
  const [tab, setTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  const submit = () => {
    api.getCloudformation(awsID).then(s => setRedir(s)).catch(e => console.error(e));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAWSID(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();
  };


  let input = (
    <form onSubmit={handleSubmit}>
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="accountId"
            name="accountId"
            label="AWS Account ID"
            type="text"
            value={awsID}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" color="primary" type="submit">Connect</Button>
        </Grid>
      </Grid>
    </form>
  );
    useEffect(() => {
      if (!redir) {
        return;
      }
      const urlObj = new URL('https://omg.com'+(new URL(redir).hash.substr(1)));
      const templateUrl = urlObj.searchParams.get('templateURL') ?? '';
      fetch(templateUrl).then(a => a.text()).then(a => setPolicy(a)).catch(e => console.error(e));
    }, [redir])
  if (redir) {
    // alert(JSON.stringify(urlObj))
    //

    input = (
      <>
        <Typography sx={{ mt: 5}}>
        Now simply <Button component="a" variant="contained" target="_blank" href={redir}>Run this stack</Button> to link your account, then come back here to validate.
      </Typography>
        { policy && <Typography sx={{my:2}}>
        If you'd like to, feel free to check over the generated yaml file beforehand. This same policy file can be accessed after clicking the above link by pasting the URL shown in the cloudformation console.
          <Typography component="pre" sx={{my: 4}}>{policy}</Typography>
      </Typography> }
      </>
    )
  }


  return (
    <Container maxWidth="md" sx={{ p: 5 }}>
      <Typography variant="h4">
        We need to link Your AWS Account to Microcumulus
      </Typography>
      <Typography sx={{my: 3}}>
        We've generated a unique AWS user name in our account for your use. The
        two options below will grant that user permission to assume-role in
        your account, and create the least-privileged user suggested by kops.
        This includes the permissions <CodeList array={permissions} />.
        {/* <List> */}
        {/* </List> */}
      </Typography>
      <Paper sx={{ mt: 2, width: '100%', mb: 2 }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="mu CLI" />
          <Tab label="Cloudformation Stack" />
        </Tabs>
      </Paper>
      {tab === 0 && cliUpdate}
      {tab === 1 && input}
    </Container>
  );
};

export default LinkAccount;
