import { createTheme } from '@mui/material/styles';

const complementaryColor1 = '#ff9643';
const complementaryColor2 = '#46ff93';
const complementaryColor3 = '#ff43c7';
const complementaryColor4 = '#a843ff';
const complementaryColor5 = '#ffed43';
const darkBackgroundColor = '#1a1e23';
const textColor = '#e0e0e0';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#43d3ff' },
    secondary: { main: complementaryColor1 },
    // Add more colors as needed
    success: { main: complementaryColor2 },
    error: { main: complementaryColor3 },
    warning: { main: complementaryColor4 },
    info: { main: complementaryColor5 },
    background: { default: darkBackgroundColor, paper: darkBackgroundColor },
    text: { primary: textColor, secondary: textColor },
  },
});

export default darkTheme;
