import { useState, useEffect } from 'react';
import { useAuth, User } from 'oidc-react';
import { ClusterStatus } from '../types/cluster';
import { clusterApi } from '../api/clusterApi';

let cache: ClusterStatus[];

export default function useClusters(refresh = true) {
  const [clusters, setData] = useState<ClusterStatus[]>(cache || []);
  const [loading, setLoading] = useState(!cache?.length);
  const [error, setError] = useState(null as any);
  const auth = useAuth();
  const api = clusterApi(auth.userData as User)
  // const [api, setApi] = useState(clusterApi(auth.userData as User));

  // useEffect(() => {
  //   setApi(clusterApi(auth.userData as User));
  // }, [auth?.userData?.access_token, auth.userData, auth?.userData?.expired]);

  useEffect(() => {
    const loadClusters = async () => {
      const c = await api.listClusters();
      setLoading(false);
      try {
        cache = c;
        setData(c || []);
      } catch (e) {
        setError(e);
        console.error('Error loading cluster data: ', e);
        setData([]);
      }
    }

    try {
      loadClusters()
      if (!refresh) {
        return
      }
      const t = setInterval(loadClusters, 10_000)
      return () => {
        clearInterval(t)
      };
    } catch(e) {
      setError(e)
    }
  }, [auth?.userData?.access_token, refresh])

  return {
    loading,
    error,
    clusters,
    createCluster: api.createCluster,
    deleteCluster: api.deleteCluster,
    refreshCluster: api.refreshCluster,
    checkExists: api.checkExists,
    listDNS: api.listDNS,
  }
};
