import React from 'react';
import {
  Container,
  Typography,
} from '@mui/material';
import {
  Info,
} from '@mui/icons-material';

interface Tool {
  name: string;
  cost: number;
  url: string;
  total?: boolean;
  min?: number;
}
const tools: Tool[] = [{
  name: 'Slack',
  cost: 12.50,
  url: 'https://slack.com/pricing',
}, {
  name: 'GitHub',
  cost: 19.25,
  url: "https://github.com/pricing",
}, {
  name: 'Jira',
  cost: 15.25,
  url: "https://www.atlassian.com/software/jira/pricing",
}, {
  name: 'Confluence',
  cost: 11,
  url: "https://www.atlassian.com/software/confluence/pricing",
}, {
  name: 'PagerDuty',
  cost: 41,
  url: "https://www.pagerduty.com/pricing/",
}, {
  name: 'Datadog',
  cost: 1500,
  total: true,
  url: "https://www.datadoghq.com/pricing/",
}, {
  name: 'Okta',
  cost: 15,
  min: 1500,
  url: "https://www.okta.com/pricing/",
// }, {
//   name: 'Venafi',
//   cost: 20,
//   url: "https://www.venafi.com/platform/pricing",
}];

export function Replaces({orgSize}: {orgSize: number}) {
  return (
    <>
    <Container sx={{ py: 6 }}>
    <Typography variant="h4" sx={{ mb: 2 }}>
    Microcumulus Replaces the following tools:
    </Typography>
    {tools.map((tool) => (<Cost orgSize={orgSize} tool={tool} />))}
    <Typography variant="h5" sx={{ mb: 2 }}>
    Total tool savings: {new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(totalPrice(orgSize))}
    </Typography>
    </Container>
    </>
  );
};

export function Cost({orgSize, tool}: {orgSize: number, tool: Tool}) {
  let costLine = (
    <span> {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(tool.cost)} (avg) per month</span>
  )

  const cost = orgSize * (tool.total ? 1 : tool.cost);

  if (!tool.total) {
    if (tool.min && cost < tool.min) {
      costLine = (
        <span> {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(tool.min)} (minimum) per month</span>
      )
    } else {
      costLine = (
        <span>{orgSize} x ${tool.cost} per month: {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          }).format(cost)}
        </span>
      );
    }
  }
  return (
    <Typography variant="h5" sx={{ mb: 2 }}>
    {tool.name}: {costLine}
    <a href={tool.url} target="_blank" rel="noopener noreferrer">
    <Info sx={{ ml: 1 }} />
    </a>
    </Typography>
  );
}

export function totalPrice(orgSize: number) {
  return tools.reduce((acc, tool) => acc+cost(orgSize, tool), 0);
}

export function cost(orgSize: number, tool: Tool) {
  if (tool.total) {
    return tool.cost;
  }
  if (tool.min && ((tool.cost*orgSize) < tool.min)) {
    return tool.min;
  }
  return orgSize * tool.cost;
}
