import {
  CircularProgress,
  Typography,
} from '@mui/material';

interface LoaderProps {
  text?: string;
}

const Loader = ({text = "Loading"}: LoaderProps) => {
  return (
    <Typography variant="h2" sx={{ textAlign: 'center', mt: 10 }}>{text} <CircularProgress /></Typography>
  );
};

export default Loader;
