import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Container } from '@mui/material';

const policy = `# PRIVACY POLICY

## INTRODUCTION

Microcumulus takes your privacy seriously. We created this Privacy Policy to explain how we collect, use, share, and protect your Information when you use our services ("Services").

### INFORMATION WE COLLECT

We collect different types of information:

1. Personal Information: Your full name, contact information, and your payment details if you're using paid features.
2. Usage Information: Data from your interaction with our services, including logs, device information, etc.

### HOW WE USE YOUR INFORMATION

We primarily use your information to provide and enhance the Services. For example, we use:

1. Personal Information for account setup, transaction processing, user support, product updates, etc.
2. Usage Information to troubleshoot and protect against errors, frauds, identity theft, etc.

### HOW WE SHARE YOUR INFORMATION

We do not sell, rent or share your personal data with third parties outside Microcumulus without your consent, except to operate Services, comply with law, or protect our rights.

### DATA SECURITY

We employ robust security measures to protect your data. These measures include encryption of your data in transit and at rest, frequent software/security updates, and regular monitoring for vulnerabilities.

### YOUR RIGHTS AND CHOICES

You have several rights in relation to your personal information:

1. Access: Obtain a copy of your personal data.
2. Modify: Request to edit inaccurate information.
3. Delete: Request us to delete your data per applicable laws.
4. Limit: Limit our use of your data.
5. Opt-out: Withdraw consent for data collection at any time.

### CHANGES TO THIS POLICY

We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the “last updated” date at the top of this page.

### CONTACT US

If you have any questions about this privacy policy, please contact us at privacy@microcumulus.com.

We are committed to protect your privacy while providing you with world-class cloud services with best-in-class open source software and practices. Thank you for choosing Microcumulus.
`;

export const PrivacyPolicy = () => {
  return <Container><ReactMarkdown>{policy}</ReactMarkdown></Container>;
};
