import React, { useState } from 'react';
import {
  Box,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { Delete, Autorenew } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

import Cost from '../components/cost';
import AppCard from '../components/appcard';
import Loader from '../components/loader';
import useCluster from '../hooks/cluster';
// import { ClusterStatus, ClusterApp } from '../types/cluster';

export const SingleCluster = () => {
  const { cluster } = useParams();
  const { loading, cluster: c, deleteCluster, refreshCluster, apps, kubeconfig } = useCluster(cluster || '');
  const [showConf, setShowConf] = useState(false);

  if (loading) {
    return (
      <Loader />
    );
  }

  if (!c || !c.name) {
    return (
      <Loader text="Loading or cluster missing"/>
    );
  }

  const failures = c?.micro?.failures && (
    <>
      <Typography color="textPrimary" variant="h5">
        Failures:
      </Typography>
      {
        c.micro?.failures?.map((fail) => (<>
          <Box sx={{ mt: 1, mb: 1 }}>
            {fail}
          </Box>
          <Divider />
        </>))
      }
    </>
  );

  let renderedLinks: any = (<Typography>Waiting for apps</Typography>);

  if (apps?.length) {
    renderedLinks = apps.map(p => (
      <Grid key={p.name} item>
        <AppCard app={p}  c={c}/>
      </Grid>
    ));
  }

  const links = (
    <>
      <Typography variant="h4">
        Apps
      </Typography>
      <Grid container justifyContent="space-between" spacing={3} sx={{m: 3}}>
        {renderedLinks}
      </Grid>
    </>
  );

  const details = [
    (<Cost variant="h4" pennies={c.micro.usage} />)
  ];
  if (c?.micro?.stage !== "done") {
    details.push(
      <Typography variant="h4">Stage: {c?.micro?.stage}</Typography>
    );
  }


  return (
    <Container>
      <Box>
        <Typography variant="h3">{c?.name}</Typography>
        <Typography variant="h3">Created {formatDistanceToNow(new Date(c.created))} ago</Typography>
      </Box>
      <Box>
        {details}
      </Box>
      {failures}
      <Box>
        <FormControlLabel
          control={
          <IconButton aria-label="Delete cluster" onClick={() => deleteCluster(c?.name)}>
            <Delete/>
          </IconButton>
              }
  label="Delete cluster"
        />

        <FormControlLabel control={
          <IconButton aria-label="refresh cluster" onClick={() => refreshCluster(c?.name)}>
            <Autorenew />
          </IconButton>
              }
  label="Refresh/rebuild"
        />
        <FormControlLabel control={
          <Switch aria-label="show kubeconfig" onClick={() => setShowConf(!showConf)} />
            }
  label="Show KubeConfig"
        />
      </Box>
      { showConf && <Box component="pre"> 
        {kubeconfig}
      </Box>
      }
      <Box sx={{ marginTop: '2rem' }}>
        {links}
      </Box>
    </Container>
  );
}
