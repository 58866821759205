import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import  { Typography, Container } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import useStripe from '../hooks/stripe';
import CreditCardForm from '../components/signup';
import { stripeApiConfig } from '../config';
import Loader from '../components/loader';

export const SignupForm = () => {
  const { getStripeIntent, saveStripeIntent } = useStripe();
  const stripePromise = loadStripe(stripeApiConfig.token);

  const navigate = useNavigate();

  const q = new URLSearchParams(window.location.search);
  const [secret, setSecret] = useState(q.get('setup_intent_client_secret'));
  const intent = q.get('setup_intent');

  useEffect(() => {
    if (intent) {
      saveStripeIntent(intent)
      .then(() => {
        navigate('/cluster/new');
      }).catch((err) => {
        console.error(err);
        // navigate('/signup', { replace: true });
      });
      return;
    }
    getStripeIntent()
      .then(i => setSecret(i.clientSecret))
      .catch(e => console.error('stripe intent get error', e));
  }, [intent, getStripeIntent, navigate, saveStripeIntent]);

  if (intent) {
    return (
      <>
        <Typography variant="h2">Sign up</Typography>
        <Typography>Thank you for signing up. Redirecting you back to cluster creation shortly.</Typography>
      </>
    );
  }

  if (!secret) {
    return (
      <Loader text="Loading payment form" />
    );
  }
  return (
    <Container>
      <Typography variant="h2">Sign up</Typography>
      <Typography sx={{ m: 2 }}>
        In order to set up infrastructure and charge you for Microcumulus services, we need your payment information. Please enter your details via the Stripe&trade; form  below.
      </Typography>
      <Typography sx={{ m: 2 }}>
        None of this information is handled directly by Microcumulus, but is stored and managed by Stripe, where you will have access to manage payment details and subscriptions directly if you ever need to.
      </Typography>
<Typography sx={{ m: 2 }}>
  Important to note that your billing address will be used to calculate sales tax. If this is not desired, please contact us at support@microcumulus.com.
      </Typography>
      <Elements stripe={stripePromise} options={{ clientSecret: secret }}>
        <CreditCardForm clientSecret={secret} />
      </Elements>
    </Container>
  );
}
