import React from 'react';
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
// import { Link } from 'react-router-dom';
import {
  BarChart as ScalableIcon,
  Build as AutomationIcon,
  Security as SecurityIcon,
  GitHub as GitHubIcon,
  Visibility as ObservabilityIcon,
  Extension as EcosystemIcon,
  DeveloperMode as DeveloperIcon,
  Cloud as DeploymentIcon,
  Key,
} from '@mui/icons-material';

export function Home() {
  // const classes = useStyles();

  const benefits = [
    { text: 'Open Source Dev Platform', icon: <GitHubIcon /> },
    { text: 'Ultimate Portability; No Lock-in', icon: <Key /> },
    { text: 'Robust Security', icon: <SecurityIcon /> },
    { text: 'Scalable Architecture and Processes', icon: <ScalableIcon /> },
    { text: 'Automated Workflows', icon: <AutomationIcon /> },
    { text: 'Full Observability', icon: <ObservabilityIcon /> },
    { text: 'Extensible Ecosystem', icon: <EcosystemIcon /> },
    { text: 'Streamlined Developer Experience', icon: <DeveloperIcon /> },
    { text: 'Flexible Deployment', icon: <DeploymentIcon /> },
  ];

  return (
    <Container sx={{ py: 6 }}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Welcome to Microcumulus
        <Box component="span" sx={{ m:2 }}>
          <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Logo" height="40"/>
        </Box>
      </Typography>
      <Typography variant="body1">
        The ultimate, all-in-one cloud platform, crafted exclusively to deliver top-notch software projects! Designed to
        streamline and track complex IT processes, Microcumulus bridges the gap between cutting-edge technology and seamless integration.
        Experience a seamless cloud environment built upon essentials like Kubernetes, GitLab, Vault, cert-manager, ArgoCD,
        Backstage, Matrix, OpenTelemetry, Prometheus, Grafana, Keycloak, and more!
      </Typography>

      <Typography variant="h3" sx={{ my: 2 }}>
        Why choose Microcumulus?
      </Typography>
      <List>
        {benefits.map(({ text, icon }, index) => (
        <ListItem key={index}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>
            <Typography component="span" sx={{mr: 1}}>{index + 1}.</Typography>
            <Typography component="span">{text}</Typography>
          </ListItemText>
        </ListItem>
        ))}
      </List>
    </Container>
  );
}
