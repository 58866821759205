import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import  {
  // Badge,
  Box,
  Button,
  Container,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Key, Cloud, PersonAdd, PersonRemove, Remove } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack'

import Loading from '../components/loader';
import { ClusterParams, User } from '../types/cluster'; 
import useClusters from '../hooks/clusters';
import useProfile from '../hooks/profile';

let id = 0;

export const CreateCluster = () => {
  const navigate = useNavigate();
  const { loading, profile } = useProfile();
  const {
    createCluster,
    checkExists,
    listDNS
  } = useClusters();
  const [ saving, setSaving ] = useState(false);

  const [ name, setName ] = useState('a');
  const [ domain, setDomain ] = useState('');

  const [ data, setData ] = useState({
    size: 'sm',
    users: [  {
      id,
      user: 'astuart',
      email: 'andrew@microcumulus.com',
      password: 'password',
      keys: ['ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQCaeFPZ9NxLG4wvKr6jIZq2madY17cQoxpRSWaufrUNTmgHmkDrDtPkXAQ+0atcNzgGkcMFviJbh0yY9sW56HlzEecqcKK/oR06AhWZgKjedQAU/ELZotX0q2BEVtNBwL/I9tHgShpA0kmki5pLMQD/9fONfGvdHlsNqv/SOTn6V2fWaRHkVgP91Wtn3ymicjMh8dA9SHLGhN13pe7gLKM+INOHuiYvwXXA0Z/8ne9keBsKE4UAznj3gkyTf1QWA3sfL9UIPEXqkabp63tDgG8Ay8j4H4E05fRPeLM3Zxzl9F9JVTwiG81O6catfgJ77H3tXUryAswl89+oO5t/Q5TB andrew@desktop']
    }],
  } as ClusterParams);

  const { enqueueSnackbar } = useSnackbar();
  const [conflict, setConflict] = useState(false);
  useEffect(() => {
    name && domain && checkExists(`${name}.${domain}`).then(f => setConflict(f));
  }, [name, domain])

  const [ dns, setDNS ] = useState([] as string[]);
  useEffect(() => {
    listDNS().then(f => {
      setDNS(f)
      if (!domain) {
        setDomain(f[0]);
      }
    });
  }, [loading, domain])
  
  if (loading) {
    return (<Loading />);
  }

  if (!profile.stripe) {
    navigate('/signup');
    return <Loading />;
  }
  if (!(profile.aws.id && profile.aws.canAssume)) {
    navigate('/link');
    return <Loading />;
  }

  const handleSubmit = async () => {
    setSaving(true);
    data.name = name+'.'+domain;
    // console.log(data);
    try {
      const cluster = await createCluster(data);
      setData(cluster);
      setSaving(false);

      navigate(`/cluster/${data.name}`);
    } catch (e: any) {
      enqueueSnackbar(e.message, {variant: 'error', persist: false, });
    }
  };

  const addUser = () => {
    data.users.push({id: ++id, keys: ['']} as User);
    setData({...data});
  }

  const opts = ['sm', 'lg'].map(v => (<MenuItem value={v} key={v}>{v}</MenuItem>));
  const dnsOpts = dns.map(v => (<MenuItem value={v} key={v}>{v}</MenuItem>));

  const users = data.users.map((u, i) => {
    const updateUser = (newu: User) => {
      data.users.splice(i, 1, newu)
      setData({...data});
    }
    const remove = () => {
      data.users.splice(i, 1)
      setData({...data});
    }
    return (
      <Paper key={u.id} elevation={1} sx={{ my: '10px', p: 2, pb: 5, position: 'relative' }}>
        <UserField user={u} onUpdate={updateUser}/>
        <Fab onClick={remove} sx={{ bottom: 15, right: 15, position: 'absolute' }}>
          <PersonRemove />
        </Fab>
      </Paper>
    );
  });

  return (
    <Container sx={{ p: '2rem'}}>
      <Typography variant="h1" sx={{ textAlign: 'center' }}>New Cluster</Typography>
      <form onSubmit={handleSubmit}>
        <Paper elevation={1} sx={{ p: 3 }}>
          <TextField error={conflict} sx={{ mx: 0.5 }} label="Prefix" value={name} onChange={(s) => setName(s.target.value)} />
          <FormControl sx={{ minWidth: 80, mx: 0.5 }}>
            <InputLabel>Domain</InputLabel>
            <Select label="domain" name="Domain" value={domain} onChange={(s) => setDomain(s.target.value)}>{dnsOpts}</Select>
          </FormControl>
          <FormControl sx={{ minWidth: 80, mx: 0.5 }}>
            <InputLabel>Size</InputLabel>
            <Select label="size" name="Size" value={data.size} onChange={(s) => setData({...data, size: s.target.value})}>{opts}</Select>
          </FormControl>
        </Paper>
        {users}
        <Box>
          <Grid spacing={2} container>
            <Grid item xs={2}>
              <Button variant="contained" onClick={addUser} sx={{ mr: 3 }}>
                <PersonAdd sx={{ mr: 1 }} /> Add User
              </Button>
            </Grid>
            <Grid item xs={8} />
            <Grid item xs={2}>
              <LoadingButton loading={saving} variant="contained" onClick={handleSubmit}>
                <Cloud sx={{ mr: 1 }} />
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Container>
  );
}

interface UserFieldParams {
  user: User;
  onUpdate: {(u: User): void};
}

const UserField = ({user, onUpdate}: UserFieldParams) => {
  const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({...user, [e.target.name]: e.target.value});
  };
  const addKey = () => {
    user.keys.push('');
    onUpdate({...user});
  }

  const keys = user.keys.map((key, i) => {
    const update = (e: React.ChangeEvent<HTMLInputElement>) => {
      user.keys.splice(i, 1, e.target.value)
      onUpdate({...user});
    }
    const remove = () => {
      user.keys.splice(i, 1)
      onUpdate({...user});
    }
    return (
      <Paper elevation={2} key={i} sx={{ p: 1, my: 1, position: 'relative' }}>
        <TextField fullWidth multiline value={key} onChange={update} />
        <Fab size="small" onClick={remove} sx={{ top: -5, right: -5, position: 'absolute' }}>
          <Remove />
        </Fab>
      </Paper>
    )
  })
  return (
    <>
    <TextField label="username" sx={{ mx: 0.5 }} name="user" value={user.user} onChange={handleUpdate}/>
    <TextField label="email" sx={{ mx: 0.5 }} name="email" value={user.email} onChange={handleUpdate}/>
    <TextField label="password" sx={{ mx: 0.5 }} name="password" value={user.password} onChange={handleUpdate}/>
    <Box>
      <Typography>Keys</Typography>
      {keys}
      <Button onClick={addKey} variant="contained"><Key sx={{ mr: 1 }} />Add Key</Button>
    </Box>
    </>
  )
}
