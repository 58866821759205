import { useState, useEffect } from 'react';
import { useAuth, User } from 'oidc-react';
import { ClusterStatus, ClusterApp } from '../types/cluster';
import { clusterApi } from '../api/clusterApi';

export default function useClusters(id: string) {
  const [cluster, setCluster] = useState({} as ClusterStatus);
  const [apps, setApps] = useState(null as null|ClusterApp[]);
  const [loading, setLoading] = useState(true);
  const [kubeconfig, setKubeconfig] = useState(``);
  const auth = useAuth();
  const api = clusterApi(auth.userData as User);
  // const [api, setApi] = useState(clusterApi(auth.userData as User));

  // useEffect(() => {
  //   setApi(clusterApi(auth.userData as User));
  // }, [auth.userData?.access_token, auth.userData]);

  useEffect(() => {
    const loadCluster = async () => {
      api.getCluster(id)
        .then(c => {
          setCluster(c);
          setLoading(false);
        })
        .catch(e => console.error(e));
    }

    loadCluster()
    const t = setInterval(loadCluster, 5_000)
    return () => {
      clearInterval(t)
    };
  }, [auth.userData, id])

  useEffect(() => {
    const loadApps = async () => {
      setApps(await api.getClusterApps(id))
      setKubeconfig(await api.getKubeconfig(id, true));
    };

    loadApps()
    const t = setInterval(loadApps, 5_000)
    return () => {
      clearInterval(t)
    };
  }, [auth.userData, id])

  return {
    loading,
    cluster,
    apps,
    kubeconfig,
    deleteCluster: api.deleteCluster,
    refreshCluster: api.refreshCluster,
    getCluster: api.getCluster,
    getClusterApps: api.getClusterApps,
    checkExists: api.checkExists,
    createCluster: api.createCluster,
    listDNS: api.listDNS,
  }
};
