import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Container } from '@mui/material';

const terms = `# Terms of Service

## 1. Introduction

Welcome to **Microcumulus**!

These Terms of Service ("Terms") govern your use of Microcumulus, our software suite powered by open source technologies (collectively, the "Service").

Please read these Terms carefully. Your use of the Service indicates that you agree to be bound by these Terms.

## 2. Privacy

We value your privacy. Please refer to our [Privacy Policy](/privacy) for information on how we collect, use and disclose your information.

## 3. Eligibility

You must be at least 18 years of age to use our Service. By using our Service, you represent and warrant that you are eligible.

## 4. Changes to the Terms

We reserve the right to change these Terms at any time, at our discretion. Your continued use of the Service following the posting of new Terms means that you accept those changes.

## 5. Accounts

To use our Service, you may need to create an account. You are responsible for the information you provide and maintaining the confidentiality of your account information.

## 6. Prohibited Activities

You may not engage in any activities that interfere with the Service, including, but not limited to, the distribution of malware, unauthorised access attempts, or spam.

## 7. Disclaimers

The Service is provided "as is," without any warranties. We do not warrant that the Service will meet your requirements or be uninterrupted or error-free.

## 8. Limitation of Liability

In no event will we be liable for any consequential, incidental, special, or indirect damages arising out of your use of the Service.

## 9. Governing Laws

These Terms are governed by the laws of the jurisdiction from which our Service is provided.

## 10. Contact

If you have any questions about these Terms, please contact us at support@microcumulus.com.`

export const TOS = () => {
  return <Container><ReactMarkdown>{terms}</ReactMarkdown></Container>;
};
