import { useState, useEffect } from 'react';
import { useAuth, User } from 'oidc-react';
import { clusterApi } from '../api/clusterApi';

export default function useStripe() {
  const auth = useAuth();
  const [api, setApi] = useState(clusterApi(auth?.userData as User));

  useEffect(() => {
    setApi(clusterApi(auth?.userData as User));
  }, [auth?.userData])

  return {
    getStripeIntent: api.getStripeIntent,
    saveStripeIntent: api.saveStripeIntent,
    saveAddress: api.saveAddress,
  }
};
