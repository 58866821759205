import React from 'react';
import { useAuth } from 'oidc-react';
import {
  Button,
  Container,
  Typography,
  Link
} from '@mui/material';

import { stripeApiConfig } from '../config';
import Loading from '../components/loader';
import useProfile from '../hooks/profile';

export function Profile() {
  const auth = useAuth();
  const {profile: p, loading} = useProfile();
  
  const logout = () => {
    auth.signOutRedirect();
  }

  if (loading) {
    return <Loading text="Loading profile" />
  }

  return (
    <>
    <Container>
      <Typography variant="h2" sx={{ textAlign: 'center' }}>
        Profile Page
      </Typography>
      <Link href={`https://billing.stripe.com/login/${stripeApiConfig.login}`}>Manage Billing</Link>
      <Typography>Email: {auth!.userData!.profile!.email}</Typography>
      <Typography>Name: {auth!.userData!.profile!.name}</Typography>
      <Typography>Username: {auth!.userData!.profile!.preferred_username}</Typography>
      <Typography>Stripe: {p.stripe ? 'Yes' : 'No'}</Typography>
      <Typography>AWS: {p.aws.id || 'AWS account not set up'}</Typography>
      <Typography>AWS Permissions: {p.aws.canAssume ? '👍' : 'AWS account not linked'}</Typography>
      <Button onClick={logout}>Log out</Button>
    {/* <Box> */}
    {/*   <pre>{JSON.stringify(p, null, '  ')}</pre> */}
    {/*   <pre>{JSON.stringify(auth?.userData?.profile, null, '  ')}</pre> */}
    {/* </Box> */}
    </Container>
    </>
  );
}
